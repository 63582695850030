import React from "react";

import Home from "./pages/Home";
import { toast } from "react-toastify";
import { Route, Switch } from "react-router-dom";
import Navigation from "./navigation/Navigation";
import Privacy from "./pages/Privacy";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cleaned: "deforested output will end up here!"
    };
    toast.configure();
  }

  _onSuccess = v => {
    this.setState({
      cleaned: v
    });
  };

  render() {
    return (
      <div className='App'>
        <nav className='navbar'>
          <Navigation />
        </nav>
        <div className='app-page'>
          <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/privacy' exact component={Privacy} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
