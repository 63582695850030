import React from "react";
import { config } from "../config";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./style.scss";

class RawTextForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      format: 'YAML',
      cleaned: "",
      val: `paths:
      "/validation":
        post:
          responses:
            "200":
              schema:
                type: array
                items:
                  "$ref": "#/definitions/Error"
              headers:
                test-method-response-header:
                  type: string
        get:
          x-deforest-ignore: true
          parameters:
            - name: q1
              in: query
              required: true
          responses:
            "200":
              schema:
                type: array
                items:
                  "$ref": "#/definitions/Error"
              headers:
                test-method-response-header:
                  type: string`
    };
  }

  _onChange = e => {
    const { value } = e.target;
    this.setState({
      val: value
    });
  };

  _onSelectChange = e => {
    const { value } = e.target;
    this.setState({
      format: value
    })
  }

  _onSubmit = e => {
    e.preventDefault();
    let payload = {
      data: new Buffer(this.state.val).toString("base64")
    };

    this.setState({
      loading: true
    });

    fetch(`${config.url.API_URL}/v2/templates?format=${this.state.format}`, {
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then(rsp => {
        if (!rsp.ok) {
          let err = new Error("could not process request");
          err.response = rsp;
          err.status = rsp.status;
          err.data = rsp.json();
          throw err;
        }
        return rsp.json();
      })
      .then(json => {
        let cv = new Buffer(json.data, "base64").toString("ascii");
        this.props.onSuccess(cv, this.state.format);
        this.setState({
          loading: false
        });
        this.showSuccess();
      })
      .catch(err => {
        if (err.data != null) {
          err.data.then(d => this.showError(d));
        } else {
          this.showError("an unexpected error occured", { detail: err });
        }
        this.setState({
          loading: false
        });
      });
  };

  showSuccess = () => {
    toast("Success!", {
      type: "success",
      autoClose: 2000,
      position: toast.POSITION.TOP_CENTER
    });
  };

  showError = data => {
    toast(`An error occurred:\n${data.detail}`, {
      type: "error",
      position: toast.POSITION.TOP_CENTER
    });
    console.error(
      "if you submit a bug report, please provide the contents of the 'input' field above, as well as the following data as well:"
    );
    console.error(
      `error: ${data.title}, code: ${data.status}, details: ${data.detail}`
    );
  };

  render() {
    return (
      <form onSubmit={this._onSubmit} className='raw-form'>
        <LoadingOverlay active={this.state.loading} spinner text='Cleaning!'>
          <textarea
            onChange={this._onChange}
            value={this.state.val}
            disabled={this.state.loading}
          />
          <div className="button-container">
            <button type='submit' disabled={this.state.loading}>
              Clean!
            </button>
            <select value={this.state.format} onChange={this._onSelectChange}>
              <option>YAML</option>
              <option>JSON</option>
            </select>
          </div>
        </LoadingOverlay>
      </form>
    );
  }
}

export default RawTextForm;
