import React from "react";

import RawTextForm from "../components/RawTextForm";
import CleanedTextField from "../components/CleanedTextField";
import { toast } from "react-toastify";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cleaned: "deforested output will end up here!",
      format: 'yaml'
    };
    toast.configure();
  }

  _onSuccess = (v,fmt) => {
    this.setState({
      cleaned: v,
      format: fmt
    });
  };

  render() {
    return (
      <div className='deforest-main'>
        <div className='deforest-description'>
          <h1>deforest online</h1>
          <p>
            Clean your Open API 3 Specification from AWS extension tags, and
            remove individual paths and/or operations using the deforest tool.
            This service uses the deforest tool available at{" "}
            <a
              href='https://github.com/hawry/deforest'
              target='_blank'
              rel='noopener noreferrer'
            >
              https://github.com/hawry/deforest
            </a>{" "}
            and is free to use. Paste your OAS3 specification or a
            CloudFormation template containing an AWS::ApiGateway::RestApi
            resource with an OAS3 body in it in the field below and press
            "Clean".
          </p>
        </div>
        <div className='app-wrapper'>
          <div className='raw-text'>
            <h3>Input</h3>
            <RawTextForm onSuccess={this._onSuccess} />
          </div>
          <div className='clean-text'>
            <h3>Output</h3>
            <CleanedTextField value={this.state.cleaned} format={this.state.format} />
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
