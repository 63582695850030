import React from "react";
import "./style.scss";

class CleanedTextField extends React.Component {
  _onPress = e => {
    this.clean.select();
    document.execCommand("copy");
  };

  _processed = () => {
    return "deforested output will end up here!" !== this.props.value;
  };

  _downloadAsFile = () => {
    const element = document.createElement("a");
    const file = new Blob([this.props.value], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `deforest-specification.${this.props.format.toLowerCase()}`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  render() {
    return (
      <div className='clean-text-field'>
        <textarea
          ref={c => (this.clean = c)}
          value={this.props.value}
          readOnly
        />
        <div className='button-container'>
          <button
            className='button-outline'
            onClick={this._onPress}
            disabled={!this._processed()}
          >
            Copy to clipboard
          </button>
          <button
            className='button-outline'
            onClick={this._downloadAsFile}
            disabled={!this._processed()}
          >
            Save to file
          </button>
        </div>
      </div>
    );
  }
}

export default CleanedTextField;
