import React from "react";
import { Link } from "react-router-dom";
class Navigation extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className='navbar-top-center'>
          <ul>
            <li>
              <Link to='/privacy'>Privacy</Link>
            </li>
            <li>|</li>
            <li>
              <a
                href='https://www.buymeacoffee.com/hawry'
                target='_blank'
                rel='noopener noreferrer'
              >
                Buy me a coffee
              </a>
            </li>
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default Navigation;
