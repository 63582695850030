let prod = {
  url: {
    API_URL: "https://deforest.hawry.net/api"
  }
};

let dev = {
  url: {
    API_URL: `http://${window.location.hostname}:8080`
  }
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
